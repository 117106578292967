const state = {
	appMount: false,
	loginCheck: false,

	showNavigation: false,
	showToolbar: true,
	applications: [ ],
	modules: [ ]
}

const getters = {
	active: state => state.appMount && state.loginCheck,
	modules: state => state.modules.map((v) => {
		let r = { ...v }
		if (r.identifier) r['url'] = '/' + r.identifier

		if (r.items) {
			r.items = r.items.map((i) => {
				let ii = { ...i }
				if (ii.identifier) ii['url'] = '/' + ii.identifier

				return ii
			})
		}
		return r
	})
}

const mutations = {
	toggleNavigation (state, p) {
		state.showNavigation = p
	},
	toggleToolbar (state, p) {
		state.showToolbar = p
	},
	appMounted (state) {
		state.appMount = true
	},
	loginChecked (state) {
		state.loginCheck = true
	},
	setModules (state, p) {
		state.modules = p
	},
	setApplications (state, p) {
		state.applications = p
	},
	terminate (state) {
		state.modules = []
		state.applications = []
	}
}

const actions = {
	init (ctx) {
		ctx.dispatch('core/modules/load', null, { root: true }).then(() => {
			ctx.commit('loginChecked')
		})
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
}
