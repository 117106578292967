import axios from 'axios'

export default {
	login (credentials) {
		return axios.post('/api/auth/login', credentials)
	},
	logout () {
		return axios.get('/api/auth/logout')
	},
	checkLogin () {
		return axios.get('/api/auth/login')
	}
}
