<template>
	<v-dialog v-model="show" persistent :max-width="maxWidth">
		<v-card>
			<slot name="title">
				<v-card-title v-if="title" class="body-2">{{ title }}</v-card-title>
			</slot>
			<slot name="text">
			<v-card-text v-bind:class="{ 'pt-5': !title }"><slot></slot></v-card-text>
			</slot>
			<v-card-actions>
			<slot name="actions">
				<v-spacer v-if="!buttonsAlign || buttonsAlign === 'right'"></v-spacer>
				<v-btn v-for="(b, i) in buttons" :loading="b.primary && loading" v-show="b.primary || (!b.primary && !loading)" :key="i" @click.stop="invokeAction(b)" text v-bind="b">{{ b.name | translate }}</v-btn>
				<v-spacer v-if="!buttonsAlign"></v-spacer>
			</slot>
			</v-card-actions>
		</v-card>
		<slot name="activator" slot="activator"></slot>
	</v-dialog>
</template>
<script>

export default {
	props: {
		value: Boolean,
		title: String,
		buttons: Array,
		buttonsAlign: String,
		loading: Boolean,
		maxWidth: {
			type: [ String, Number ],
			default: 290
		}
	},
	methods: {
		invokeAction (a) {
			this.$emit('action', a)
		}
	},
	computed: {
		show: {
			get () {
				return this.value
			},
			set (v) {
				this.$emit('input', v)
			}
		}
	}
}
</script>
