import Vue from 'vue'
import Vuex from 'vuex'

// core stores
import appToolbar from './core/appToolbar'
import snackbar from './core/snackbar'
import wIframeDialog from './core/wIframeDialog'
import auth from './core/auth'
import modules from './core/modules'
import desktop from './core/desktop'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
	modules: {
		core: {
			namespaced: true,
			modules: {
				appToolbar,
				snackbar,
				wIframeDialog,
				auth,
				modules,
				desktop
			}
		}
	},
	strict: debug
})
