var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-list',[(_vm.columns && _vm.items.length)?_c('v-list-item',[(_vm.hasAvatarSlot || _vm.selectable)?_c('v-list-item-action',[(_vm.selectable)?_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.topSelectorClick}},[_c('v-icon',[_vm._v(_vm._s(_vm.topSelectorIcon))])],1):_vm._e()],1):_vm._e(),(_vm.value.length === 0)?_vm._l((_vm.columns),function(c){return _c('v-list-item-content',{key:c.headerText,class:c.class},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(c.headerText)}})],1)}):_vm._e(),(_vm.value.length > 0)?[_vm._t("selector-actions"),_c('v-spacer'),_c('v-list-item-content',{staticClass:"col-2"},[_c('v-list-item-title',{staticClass:"font-weight-bold text-right"},[_vm._v(_vm._s(_vm.topSelectedText))])],1)]:_vm._e(),(_vm.actions.length)?_c('v-list-item-action',{staticStyle:{"width":"36px"}},[_vm._t("header-action")],2):_vm._e()],2):_vm._e(),(_vm.columns && _vm.items.length)?_c('v-divider'):_vm._e(),_c('v-progress-linear',{attrs:{"active":_vm.loading,"indeterminate":""}}),(_vm.items.length)?[_vm._l((_vm.items),function(item,index){return [_c('v-hover',{key:index+99999,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-list-item',_vm._b({key:index+999999,on:{"click":function($event){return _vm.invokeAction({ name: 'default', item: item })}}},'v-list-item',{ twoLine: _vm.twoLine },false),[(_vm.hasAvatarSlot || _vm.selectable)?_c('v-list-item-action',[(!_vm.hasAvatarSlot || _vm.selectable && hover || _vm.value.indexOf(item[_vm.itemKey]) != -1)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.toggleItemSelection(item)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.value.indexOf(item[_vm.itemKey]) === -1 ? 'mdi-checkbox-blank-outline':'mdi-checkbox-marked'))])],1):_vm._t("avatar",null,{"item":item})],2):_vm._e(),_vm._l((_vm.columns),function(col,idx){return _c('v-list-item-content',{key:idx+9999,class:col.class},[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(item[col.value])}}),(col.valueSubTitle && item[col.valueSubTitle])?_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(item[col.valueSubTitle])}}):_vm._e()],1)}),(_vm.actions && _vm.actions.length)?_c('v-list-item-action',[(_vm.actions.length === 1)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.invokeAction({ name: _vm.actions[0].name, item: item })}}},'v-btn',attrs,false),on),[_c('v-icon',{domProps:{"textContent":_vm._s(_vm.actions[0].icon)}})],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(_vm.actions[0].text)}})]):_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.actions),function(action,idx){return _c('v-list-item',{key:idx,on:{"click":function($event){$event.stopPropagation();return _vm.invokeAction({ name: action.name, item: item })}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v(_vm._s(action.icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(action.text))])],1)}),1)],1)],1):_vm._e()],2)]}}],null,true)})]})]:_vm._e(),(!_vm.items.length && !_vm.loading)?_vm._t("emptyList",[_c('v-alert',{attrs:{"type":"info","icon":"mdi-information","value":true}},[_vm._v(_vm._s(_vm.emptyText))])]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }