<template>
<transition name="slideDown">
<div class="loginWindow" v-if="!logged">
<div class="loginFormBox">
	<div class="loginImage"><v-img :src="require('../../assets/logo-login.png')" alt="Delivo"></v-img></div>
<v-text-field v-model="username" placeholder=" " label="Login" :error-messages="formErrorsMsg.username" @keyup.enter="$refs.pwd.focus()"></v-text-field>
<v-text-field label="Heslo" placeholder=" " @keyup.enter="invokeLogin" ref="pwd" :error-messages="formErrorsMsg.password" v-model="password" :type="showPassword ? 'password':'text'" :append-icon="showPassword ? 'mdi-eye':'mdi-eye-off'" @click:append="() => (showPassword = !showPassword)"></v-text-field>
<div class="text-center pb-2"><v-btn text color="primary" :loading="loading" @click="invokeLogin">{{ 'Sign In' | translate }}</v-btn></div>
</div>
</div>
</transition>
</template>

<script>
import { mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'

export default {
	name: 'login',
	data () 	{
		return {
			showPassword: true
		}
	},
	computed: {
		...mapFields('core/auth', [
			'loginForm.username', 'loginForm.password'
		]),

		...mapState('core/auth', [ 'loading', 'formErrors', 'logged' ]),
		formErrorsMsg () {
			return Object.keys(this.formErrors).reduce((r, k) => {
				r[k] = this.$options.filters.translate(this.formErrors[k])
				return r
			}, {})
		}
	},
	methods: {
		invokeLogin () {
			this.$store.dispatch('core/auth/login')
		}
	},
	mounted () {
		this.$nextTick(() => this.$store.dispatch('core/auth/checkLogin'))
	}
}
</script>

<style lang="scss" scoped>
.loginWindow {
	position: fixed;
	width: 100%;
	height: 100%;
	background: white;
	background: linear-gradient(-45deg, #efefef, #fff);
	z-index: 999;
	display: flex;
	justify-content: center;
	align-items: center;
}

.loginImage {
	padding: 0 50px;
	padding-bottom: 10px;
	text-align: center;

	img {
		max-width: 100%;
		display:inline-block;
	}
}

.slideDown-enter, .slideDown-leave-to {
	transform: translateY(-100%);
}

.slideDown-enter-active, .slideDown-leave-active {
	transition: all 1s ease;
}

.loginFormBox {
	max-width: 90%;
	padding: 20px;
	width: 300px;

	img {
		margin-bottom: 20px;
	}
}
</style>
