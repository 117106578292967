const data = {
	// Form validation
	'ValidatorWrongType': 'Položka má nesprávny typ',
	'ValidatorRequired': 'Položka je povinná',
	'ValidatorTextMinVal': 'Minimálne {param0} znakov',
	'ValidatorTextMaxVal': 'Maximálne {param0} znakov',
	'ValidatorNumMinVal': 'Minimálna hodnota je {param0}',
	'ValidatorNumMaxVal': 'Maximálna hodnota je {param0}',
	'ValidatorDateMinVal': 'Dátum môže byť min {param0}',
	'ValidatorDateMaxVal': 'Dátum môže byť max {param0}',
	'ValidatorNumNotNum': 'Musí byť číslo',
	'ValidatorNumIsNegative': 'Nesmie byť negatívna',
	'ValidatorNumIsFloat': 'Musí byť celé číslo',
	'ValidatorNumManyDecimals': 'Max. {param0} desatinné miesta',
	'ValidatorDateNotDate': 'Dátum má nesprávny formát',
	'ValidatorDateMinNotDate': 'Chybný formát min. dátumu',
	'ValidatorDateMaxNotDate': 'Chybný formát max. dátumu',
	'ValidatorEmailNotEmail': 'Email má chybný formát',
	'ValidatorUrlNotUrl': 'Adresa nie je správna',
	'ValidatorRegexpNoMatch': 'Nesprávny formát',
	'ValidatorRegexpBad': 'Chybný regexp',

	// Dialog buttons
	'DlgSave': 'Uložiť',
	'DlgClose': 'Zavrieť',
	'DlgCancel': 'Zrušiť',
	'DlgYes': 'Áno',
	'DlgNo': 'Nie',
	'DlgOk': 'Ok',

	// Action phrases
	'Add': 'Pridať',
	'Edit': 'Upraviť',
	'Delete': 'Vymazať',

	// Login
	'Sign In': 'Prihlásiť',
	'Password Recovery': 'Obnovenie hesla',
	'Submit': 'Odoslať',
	'Wrong username or password': 'Chybný login alebo heslo',

	// Base phrases
	'Email': 'Email',
	'Date': 'Dátum',
	'Password': 'Heslo',
	'Number': 'Číslo',
	'Type': 'Typ',
	'Title': 'Názov',
	'Search': 'Hľadať',
	'Code': 'Kód',
	'VAT': 'DPH',
	'Labels': 'Štítky',
	'Label': 'Štítok',
	'Selection': '{count} záznam ::: {count} záznamy ::: {count} záznamov',
	'Weight': 'Hmotnosť',
	'Settings': 'Nastavenia',
	'Numbering': 'Číslovanie',
	'New item': 'Nová položka',
	'Basic': 'Základné',

	// Error messages
	'Wrong request': 'Chybná požiadavka',
	'System failure': 'Došlo k systémovej chybe',
	'The form contains errors': 'Formulár obsahuje chyby',
	'Record could not be found': 'Záznam sa nepodarilo nájsť',
	'Record could not be deleted': 'Záznam nie je možné vymazať',
	'Error storing record': 'Chyba pri ukladaní záznamu',
	'Error deleting record': 'Záznam sa nepodarilo vymazať',

	// Depots
	'Depots': 'Depá',
	'Depot': 'Depo',
	'Allowed transport to': 'Povoliť transport do',

	// Vehicles
	'Vehicles': 'Vozidlá',
	'Plate number': 'Evidenčné číslo',
	'Carrying capacity': 'Nosnosť',
	'Vehicle type': 'Typ vozidla',

	// Users
	'Users': 'Používatelia',
	'Driver': 'Vodič',
	'Rights': 'Práva',
	'Mobile app': 'Mobilná aplikácia',
	'App Pin': 'Pin aplikácie',

	// Routes
	'Routes': 'Cestovné plány',
	'Route': 'Cestovný plán (rollkarta)',

	// Shipments
	'Shipment': 'Zásielka'
}

export default {
	data,
	locale: 'sk'
}
