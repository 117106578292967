import Vue from 'vue'
import Router from 'vue-router'
import Users from './users'
import Depots from './depots'
import Dashboard from '@/components/core/Dashboard'

Vue.use(Router)

export default new Router({
	routes: [{
		path: '/',
		name: 'Dashboard',
		component: Dashboard
	},
		...Users,
		...Depots
	]
})
