<template>
	<v-list>
		<v-list-item v-if="columns && items.length">
			<v-list-item-action v-if="hasAvatarSlot || selectable">
				<v-btn v-if="selectable" @click="topSelectorClick" icon><v-icon>{{ topSelectorIcon }}</v-icon></v-btn>
			</v-list-item-action>

			<template v-if="value.length === 0"><v-list-item-content v-for="c in columns" :key="c.headerText" :class="c.class"><v-list-item-title v-text="c.headerText"></v-list-item-title></v-list-item-content></template>

			<template v-if="value.length > 0">
				<slot name="selector-actions"></slot>
				<v-spacer></v-spacer>
				<v-list-item-content class="col-2"><v-list-item-title class="font-weight-bold text-right">{{ topSelectedText }}</v-list-item-title></v-list-item-content>
			</template>

			<v-list-item-action v-if="actions.length" style="width: 36px"><slot name="header-action"></slot></v-list-item-action>
		</v-list-item>
		<v-divider v-if="columns && items.length"></v-divider>
		<v-progress-linear :active="loading" indeterminate></v-progress-linear>

		<template v-if="items.length">
		<template v-for="(item, index) in items">
			<v-hover v-slot:default="{ hover }" :key="index+99999">
			<v-list-item :key="index+999999" @click="invokeAction({ name: 'default', item })" v-bind="{ twoLine }">
				<v-list-item-action v-if="hasAvatarSlot || selectable">
					<v-btn v-if="!hasAvatarSlot || selectable && hover || value.indexOf(item[itemKey]) != -1" @click.stop="toggleItemSelection(item)" icon>
						<v-icon>{{ value.indexOf(item[itemKey]) === -1 ? 'mdi-checkbox-blank-outline':'mdi-checkbox-marked' }}</v-icon>
					</v-btn>
					<slot name="avatar" :item="item" v-else></slot>
				</v-list-item-action>
				
				<v-list-item-content v-for="(col, idx) in columns" :key="idx+9999" :class="col.class">
					<v-list-item-title v-html="item[col.value]"></v-list-item-title>
					<v-list-item-subtitle v-if="col.valueSubTitle && item[col.valueSubTitle]" v-html="item[col.valueSubTitle]"></v-list-item-subtitle>
				</v-list-item-content>

				<v-list-item-action v-if="actions && actions.length">
					<v-tooltip v-if="actions.length === 1" bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn v-bind="attrs" v-on="on" @click.stop="invokeAction({ name: actions[0].name, item })" icon><v-icon v-text="actions[0].icon"></v-icon></v-btn>
						</template>
						<span v-text="actions[0].text"></span>
					</v-tooltip>
					<v-menu bottom left v-else>
						<template v-slot:activator="{ on }"><v-btn v-on="on" icon><v-icon>mdi-dots-vertical</v-icon></v-btn></template>
						<v-list>
							<v-list-item v-for="(action, idx) in actions" :key="idx" @click.stop="invokeAction({ name: action.name, item })">
								<v-list-item-action><v-icon>{{ action.icon }}</v-icon></v-list-item-action>
								<v-list-item-title>{{ action.text }}</v-list-item-title>
							</v-list-item>
						</v-list>
					</v-menu>
				</v-list-item-action>
			</v-list-item>
			</v-hover>
		</template>
		</template>
		<slot v-if="!items.length && !loading" name="emptyList"><v-alert type="info" icon="mdi-information" :value="true">{{ emptyText }}</v-alert></slot>
	</v-list>
</template>
<script>
import { union, without, difference } from 'lodash'

export default {
	// name: 'w-list2',
	props: {
		twoLine: Boolean,
		selectable: Boolean,
		value: {	// selected item
			type: Array,
			default: () => new Array()
		},
		columns: Array,
		loading: Boolean,
		itemKey: {
			type: String,
			default: 'id'
		},
		items: Array,
		actions: {
			type: Array,
			default: () => new Array()
		},
		emptyText: {
			type: String,
			default: 'V tomto zobrazení sa nenachádzajú žiadne záznamy'
		}
	},
	computed: {
		topSelectorIcon () {
			if (this.value.length === 0) return 'mdi-checkbox-blank-outline'
			return this.items.length === this.value.length ? 'mdi-checkbox-marked' : 'mdi-minus-box'
		},
		topSelectedText () {
			return this.value.length + ' vybran' + (this.value.length === 1 ? 'ý' : (this.value.length > 1 && this.value.length < 5 ? 'é' : 'ých'))
		},
		hasAvatarSlot () {
			return !!this.$scopedSlots['avatar']
		}
	},
	methods: {
		invokeAction (p) {
			this.$emit('action', p)
		},
		topSelectorClick () {
			let itemsKeys = this.items.map(v => v[this.itemKey])
			let valDiff = difference(itemsKeys, this.value)

			if (valDiff.length > 0) {	// select all
				itemsKeys = union(this.value, itemsKeys)
			} else {
				itemsKeys = without(this.value, ...itemsKeys)
			}

			this.$emit('input', itemsKeys)
		},
		toggleItemSelection (item) {
			let newSelection = [ ...this.value ]
			let selectedIndex = newSelection.indexOf(item[this.itemKey])

			if (selectedIndex === -1) {
				newSelection.push(item[this.itemKey])
			} else {
				newSelection.splice(selectedIndex, 1)
			}

			this.$emit('item-selected', { item, value: selectedIndex === -1 })
			this.$emit('input', newSelection)
		}
	}
}
</script>
