// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import axios from 'axios'
import App from './App'
import router from './router'
import vuetify from './plugins/vuetify'
import vuexI18n from 'vuex-i18n'
import phrases from './i18n/core_sk'
import store from './store'
import './plugins/formatters'
import './components/base/_globals'

Vue.config.productionTip = false
Vue.use(vuexI18n.plugin, store)

/* eslint-disable no-new */
new Vue({
	router,
	vuetify,
	store,
	render: h => h(App),
	mounted () {
		var _t = this

		// logout trigger
		axios.interceptors.response.use(undefined, function(error) {
			// console.log('axios error response trigger', this, error, arguments)
			if (error.response.status === 401 && error.request.responseURL.indexOf('/api/auth/login') === -1) {
				_t.$store.commit('core/auth/setOnLoginTrigger', () => { axios.request(error.config) })
				_t.$store.commit('core/auth/logout')
				return
			}
			return Promise.reject(error)
		})

		this.$i18n.add(phrases.locale, phrases.data)
		this.$i18n.set(phrases.locale)
	}
}).$mount('#app')
