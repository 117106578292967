import { isObject } from 'lodash'

export function createFormErrors (model, errors = {}) {
	var res = Object.keys(model).reduce((r, k) => {
		if (Array.isArray(model[k]) && errors[k] && isObject(model[k][0])) {
			// iterate over data
			r[k] = []
			for (let i = 0, l = model[k].length; i < l; i++) {
				r[k][i] = createFormErrors(model[k][i], errors[k][i] || {})
			}
		} else r[k] = errors[k] || []
		return r
	}, {})

	return res
}
