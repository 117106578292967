import { isArray } from 'lodash'

var funcs = {
	getBy (state) {
		return (f, v) => state.data.filter(i => i[f] === v) || []
	},
	getOneBy (state) {
		return (f, v) => state.data.filter(i => i[f] === v)[0] || {}
	}
}

export default function (getters) {
	let gs = isArray(getters) ? getters : [ getters ]

	return gs.reduce((a, v) => {
		if (!funcs[v]) throw 'Function name ' + v + ' not exists'

		a[v] = funcs[v]
		return a
	}, {})
}
