<template>
	<v-card :flat="flat">
		<v-card-text>
			<v-container fluid>
				<v-layout fill-height>
					<v-flex d-flex align-center justify-center>
						<v-progress-circular indeterminate color="primary"></v-progress-circular>
					</v-flex>
				</v-layout>
			</v-container>
		</v-card-text>
	</v-card>
</template>
<script>
export default {
	props: {
		flat: {
			type: Boolean,
			default: false
		}
	}
}
</script>
