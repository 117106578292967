<template>
<v-snackbar
	:timeout="timeout"
	:top="vpos === 'top'"
	:bottom="vpos === 'bottom'"
	:right="hpos ==='right'"
	:left="hpos === 'left'"
	:color="color"
	:multi-line="mode === 'multi-line'"
	:vertical="mode === 'vertical'"
	v-model="mvisible"
>{{ text | translate }}
<template v-slot:action="{ attrs }">
	<v-btn dark text v-bind="attrs" @click="mvisible = false">{{ 'DlgClose' | translate }}</v-btn>
</template>
</v-snackbar>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'

const { mapState } = createNamespacedHelpers('core/snackbar')

export default {
	computed: {
		...mapState({
			visible: 'visible',
			vpos: 'vpos',
			hpos: 'hpos',
			color: 'color',
			mode: 'mode',
			timeout: 'timeout',
			text: 'text'
		}),
		mvisible: {
			get () { return this.visible },
			set (newVal) { this.$store.commit('core/snackbar/visibility', newVal) }
		}
	}
}
</script>
