<template>
<v-dialog v-model="showDialog" ref="refDlg" v-bind="{ persistent, fullWidth }" width="290px">
	<template v-slot:activator="{ on }"><v-text-field v-on="on" v-model="dateFormatted" v-bind="{ label, clearable, filled, errorMessages, prependIcon, solo, text, backgroundColor, hideDetails, outlined, singleLine, height }" readonly></v-text-field></template>
	<v-date-picker v-model="internalDate" locale="sk-sk" :first-day-of-week="1" scrollable>
		<v-spacer></v-spacer>
		<v-btn color="primary" text @click="showDialog = false">{{ 'DlgClose' | translate }}</v-btn>
		<v-btn color="primary" text @click="save">{{ 'DlgOk' | translate }}</v-btn>
	</v-date-picker>
</v-dialog>
</template>
<script>
export default {
	props: {
		persistent: {
			type: Boolean,
			default: true
		},
		clearable: {
			type: Boolean,
			default: false
		},
		fullWidth: {
			type: Boolean,
			default: true
		},
		label: String,
		solo: {
			type: Boolean,
			default: false
		},
		text: {
			type: Boolean,
			default: false
		},
		filled: Boolean,
		outlined: {
			type: Boolean,
			default: false
		},
		singleLine: {
			type: Boolean,
			default: false
		},
		hideDetails: {
			type: Boolean,
			default: false
		},
		errorMessages: null,
		height: null,
		value: null,
		prependIcon: null,
		backgroundColor: null
	},
	data () {
		return {
			showDialog: false,
			internalDate: null
		}
	},
	computed: {
		dateFormatted: {
			get () {
				return this.$options.filters.date(this.value)
			},
			set (v) {
				if (v === null) {
					this.$emit('input', null)
				}
			}
		}
	},
	methods: {
		save () {
			this.$emit('input', `${this.internalDate}T00:00:00.000Z`)
			this.showDialog = false
		}
	},
	watch: {
		showDialog (v) {
			if (v === true && this.value) {
				this.internalDate = this.value.substr(0, 10)
			} else {
				this.internalDate = null
			}
		}
	}
}
</script>
