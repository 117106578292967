import Vue from 'vue';
import dateFormat from 'dateformat'
import { isArray } from 'lodash'

const Formatters = {}

Formatters.install = function (Vue, store) {
	Vue.filter('currency', function (n, currencyId) {
		// get currency from store
		let currency = store.state.core.currencies.data.reduce((a, v) => v.id === currencyId ? v : a)

		if (!n) return currency.sign

		return Number(n).toFixed(2).replace(/./g, (c, i, a) => i > 0 && c !== '.' && (a.length - i) % 3 === 0 ? ' ' + c : c) + ' ' + currency.sign
	})

	Vue.filter('measureUnit', function (n, measureUnitId) {
		if (!measureUnitId) return n

		// get currency from store
		let measureUnit = store.state.core.measureUnits.data.reduce((a, v) => v.id === measureUnitId ? v : a)

		if (!n) return measureUnit.short

		return n + ' ' + measureUnit.short
	})

	Vue.filter('date', function (n) {
		if (!n) return ''

		let d = new Date(n)
		return dateFormat(d, 'd. m. yyyy')
	})

	Vue.filter('datetime', function (n) {
		if (!n) return ''

		let d = new Date(n)
		return dateFormat(d, 'd. m. yyyy h:MM:ss')
	})

	Vue.filter('formError', function (n, $t) {
		if (!n || (isArray(n) && n.length === 0)) return []
		let tp = []

		if (isArray(n)) {
			n = [ ...n ]
			tp.push(n.shift())
			if (n.length > 0) {
				let p = {}
				for (let i in n) p['param' + i] = n[i]
				tp.push(p)
			}
		} else tp.push(n)

		return $t ? $t.apply(null, tp) : tp
	})
}

Vue.use(Formatters);

// export default Formatters
