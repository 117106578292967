import API from '@/api/modules'
import gGetters from '@/store/helpersGetter'

const state = function () {
	return {
		data: [],
		loading: false
	}
}

const getters = {
	...gGetters('getOneBy')
}

const mutations = {
	setData (state, data) {
		state.data = data || []
	},
	setLoading (state, b) {
		state.loading = b
	}
}

const actions = {
	load (ctx) {
		ctx.commit('setLoading', true)

		return API.load({ load_actions: true }).then(({ data }) => {
			ctx.commit('setData', data.data)

			return data.data
		}).catch((error) => {
			ctx.commit('core/snackbar/show', { text: error.message }, { root: true })

			throw error
		}).finally(() => {
			ctx.commit('setLoading', false)
		})
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
