<template>
	<v-navigation-drawer width="300" persistent :clipped="true" v-model="visible" enable-resize-watcher temporary app>
		<v-toolbar color="primary" dark flat>
			<v-list>
				<v-list-item>
					<v-list-item-title class="title">{{ user.first_name + ' ' + user.last_name }}</v-list-item-title>
				</v-list-item>
			</v-list>
		</v-toolbar>

		<v-list dense>
            <template v-for="module in modules">
                <v-list-group v-if="module.children" :key="module.id" :prepend-icon="module.icon" no-action>
					<template v-slot:activator>
						<v-list-item-content>
							<v-list-item-title>{{ module.name }}</v-list-item-title>
						</v-list-item-content>
					</template>
                    <v-list-item v-for="subItem in module.children" :key="subItem.id" :to="subItem.identifier || null">
						<v-list-item-content>
							<v-list-item-title v-text="subItem.name"></v-list-item-title>
						</v-list-item-content>
                    </v-list-item>
                </v-list-group>
                <v-list-item v-else :key="module.id" :to="module.identifier || null">
                    <v-list-item-action><v-icon v-if="module.icon">{{ module.icon }}</v-icon></v-list-item-action>
					<v-list-item-content>
						<v-list-item-title>{{ module.name }}</v-list-item-title>
					</v-list-item-content>
                </v-list-item>
            </template>
		</v-list>
	</v-navigation-drawer>
</template>
<script>
import { mapState, mapGetters } from 'vuex'

export default {
	computed: {
		visible: {
			get () {
				return this.$store.state.core.appToolbar.drawerShow
			},
			set (v) {
				this.$store.commit('core/appToolbar/toggleMainNav', v)
			}
		},
		...mapState('core/auth', [ 'user' ]),
		...mapState('core/modules', [ 'data' ]),
		...mapGetters('core/auth', [ 'getModules' ]),
		modules () {
			let withParents = [ ...this.getModules ]
			withParents.forEach((v) => {
				let p = Math.floor(v / 1000) * 1000
				if (withParents.indexOf(p) === -1) {
					withParents.push(p)
				}
			})

			let res = []
			this.data.filter((d) => withParents.indexOf(d.id) > -1).forEach((v) => {
				if (!v.module_id) {
					res.push({ ...v })
					return
				}
				if (!res[res.length-1].children) res[res.length-1].children = []
				res[res.length-1].children.push({ ...v })
			})

			return res
		}
	}
}
</script>
