export class Login {
	constructor ({ username = '', password = '' } = {}) {
		this.username = username
		this.password = password
	}
}

export function createLogin (data) {
	return new Login(data)
}
