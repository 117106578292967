<template>
	<v-dialog v-model="show" :persistent="persistent" :max-width="maxWidth" :disabled="disabled">
		<v-card v-if="!loading">
			<slot name="title" :title="title">
				<v-card-title v-show="title" class="body-2">{{ title }}</v-card-title>
			</slot>
			<slot name="text">
				<v-card-text v-bind:class="{ 'pt-5': !title }"><slot></slot></v-card-text>
			</slot>
			<v-card-actions>
			<slot name="actions" v-if="buttonsShow">
				<slot name="actionsLeft"></slot>
				<v-spacer v-if="!buttonsAlign || buttonsAlign === 'right'"></v-spacer>
				<v-btn v-for="(b, i) in buttons" :loading="b.primary && processing" v-show="b.primary || (!b.primary && !processing)" :key="i" @click.stop="invokeAction(b)" text v-bind="b">{{ b.name }}</v-btn>
				<v-spacer v-if="!buttonsAlign"></v-spacer>
			</slot>
			</v-card-actions>
		</v-card>
		<w-dialog-loader v-else></w-dialog-loader>
		<template v-slot:activator="scope"><slot name="activator" v-bind="scope"></slot></template>
	</v-dialog>
</template>
<script>

export default {
	props: {
		value: Boolean,
		persistent: Boolean,
		disabled: Boolean,
		loading: Boolean,
		processing: Boolean,
		title: String,
		buttons: Array,
		buttonsAlign: String,
		buttonsShow: {
			type: Boolean,
			default: true
		},
		maxWidth: {
			type: [ String, Number ],
			default: 290
		}
	},
	methods: {
		invokeAction (a) {
			this.$emit('action', a)
		}
	},
	computed: {
		show: {
			get () {
				return this.value
			},
			set (v) {
				this.$emit('input', v)
			}
		}
	}
}
</script>
