import { Login } from '@/models/Login'
import loginAPI from '@/api/login'
import { createFormErrors } from '@/store/utils'
import { getField, updateField } from 'vuex-map-fields'

const state = {
	logged: false,
	user: {},
	modulesActions: [],
	onLogin: [],
	loading: false,

	formErrors: createFormErrors(new Login()),
	loginForm: new Login()
}

const getters = {
	getModules: state => state.modulesActions.map((a) => a.module_id).filter((v, i, a) => a.indexOf(v) === i),
	getField
}

const mutations = {
	login (state, p) {
		state.logged = true
		state.user = p
		state.modulesActions = p.modulesActions || []
		state.formErrors = createFormErrors(state.loginForm)

		if (state.onLogin.length > 0) {
			for (let i = 0, l = state.onLogin.length; i < l; i++) {
				let f = state.onLogin.shift()
				f()
			}
		}
	},
	setErrors (state, p = {}) {
		state.formErrors = createFormErrors(state.loginForm, p)
	},
	setLoading (state, p) {
		state.loading = p
	},
	logout (state) {
		state.logged = false
		state.user = {}
	},
	setOnLoginTrigger (state, f) {
		state.onLogin.push(f)
	},
	updateField
}

const actions = {
	login ({ dispatch, commit }) {
		commit('setLoading', true)
		loginAPI.login(state.loginForm).then(({ data }) => {
			commit('login', data)
			dispatch('core/desktop/init', null, { root: true })
		}).catch(({ response: { data } }) => {		// { response: { data } }
			commit('core/snackbar/show', { text: data.message }, { root: true })
			commit('setErrors', data.formErrors || {})
		}).finally(() => {
			commit('setLoading', false)
		})
	},
	logout ({ commit }) {
		return loginAPI.logout().then(() => {
			commit('core/desktop/terminate', null, { root: true })
			commit('logout')
		}).catch((error) => {
			commit('core/snackbar/show', { text: error.message }, { root: true })
		})
	},
	checkLogin ({ dispatch, commit }) {
		return loginAPI.checkLogin().then(({ data }) => {
			commit('login', data)

			setTimeout(() => {
				dispatch('core/desktop/init', null, { root: true })
			}, 200)

			return data
		})
	}
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}
